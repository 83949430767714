import React, { Component } from "react"
import PropTypes from "prop-types"
import "./OrbitalSystem.css"
import { SystemContext } from "./OrbitalSystem"
import { toDeg } from "./utils"


function eventToCoords(e) {
  const event = e.changedTouches
    ? e.changedTouches[0]
    : e
  return {x: event.clientX, y: event.clientY}
}

export default class DragOverlay extends Component {
  static contextType = SystemContext

  static propTypes = {
    onDrag: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.onSelect = this.onSelect.bind(this)
    this.move = this.move.bind(this)
    this.stop = this.stop.bind(this)

    this.state = {
      initialDrag: undefined,
      accumulatedDelta: 0,
    }
  }

  componentWillUnmount() {
    this.stop(false)
  }

  eventToLocalCoords(e) {
    const {x, y} = eventToCoords(e)
    const {offsets, centerX, centerY, radius} = this.context

    return {
      x: (x - offsets.x - centerX) / radius,
      y: (y - offsets.y - centerY) / radius,
    }
  }

  stop(resetState = true) {
    document.removeEventListener("touchmove", this.move)
    document.removeEventListener("mousemove", this.move)
    document.removeEventListener("touchend", this.stop)
    document.removeEventListener("touchmove", this.stop)

    const {angle, initialAngle, accumulatedDelta} = this.state
    const newAccum = angle - initialAngle

    if (resetState) {
      this.setState({
        angle: undefined,
        initialAngle: undefined,
        accumulatedDelta: isNaN(newAccum) ? accumulatedDelta : newAccum,
      })
    }
  }

  move(e) {
    e.preventDefault()
    const {accumulatedDelta} = this.state
    const angle = this.calculateDragDelta(this.eventToLocalCoords(e)) + accumulatedDelta
    const {onDrag} = this.props

    if (onDrag && this.state.angle !== angle) {
      onDrag(angle)
    }

    this.setState({angle})
  }

  calculateDragDelta({x, y}) {
    const {initialAngle} = this.state
    const currentAngle = Math.atan2(y, x)
    const delta = toDeg(currentAngle - initialAngle)
    return delta
  }

  onSelect(e) {
    e.preventDefault()

    const {x, y} = this.eventToLocalCoords(e)
    this.setState({initialAngle: Math.atan2(y, x)})

    document.addEventListener("touchend", this.stop)
    document.addEventListener("mouseup", this.stop)
    document.addEventListener("touchmove", this.move)
    document.addEventListener("mousemove", this.move)
  }

  render() {
    return (<div className='DragOverlay'
                 onTouchStart={this.onSelect}
                 onMouseDown={this.onSelect}
                 style={({
                   width: "100%",
                   height: "100%",
                   position: "absolute",
                   top: 0,
                   left: 0,
                   cursor: "move",
                 })}
    />)
  }
}

export { SystemContext }
