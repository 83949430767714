import DevTools from 'mobx-react-devtools';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import { Header } from './components/Header';
import { ClockPage, LandingPage, SigninPage } from './pages';
import { Signout } from './profile';


class App extends Component {
  render() {
    return (
      <div className="container-fluid">
        <Header/>
        <div className="main">
          <Switch>
            <Route exact path="/" component={LandingPage}/>
            <Route exact path="/signin" component={SigninPage}/>
            <Route exact path="/signout" component={Signout}/>
            <Route path="/clock/:id" component={ClockPage}/>
          </Switch>
        </div>
        <DevTools/>
        <Footer/>
      </div>
    );
  }
}

export default App;
