import PouchDB from 'pouchdb';

export type ItemContent = {
  name: string,
  city: string,
  img: string
}

export type ItemDatum = ItemContent & {
  _id: string,
  _rev?: string,
}

export default class ClockDB {
  public static async load(clockID: string): Promise<ClockDB> {
    return new ClockDB(clockID);
  }

  private readonly db: PouchDB.Database<ItemDatum>;

  constructor(clockID: string) {
    this.db = new PouchDB(`user_clock_${clockID}`);
  }

  changes({ change }: { change: () => void }) {
    const c = this.db.changes({ since: 'now', live: true, include_docs: false });
    c.on('change', change);
  };

  public set = async (id: string, { name, city, img }: ItemContent): Promise<any> => {
    const prev = await this.db.get(id).catch(err => null);
    const doc: ItemDatum = { _id: id, name, city, img };
    if (prev) {
      doc._rev = prev._rev;
    }
    return await this.db.put(doc);
  };

  public remove = async (id: string): Promise<any> => {
    const prev = await this.db.get(id);
    return this.db.remove({ _id: id, _rev: prev._rev });
  };

  public items = async (): Promise<ItemDatum[]> => {
    const xs = await this.db.allDocs({ include_docs: true });
    const valid = xs.rows.filter(x => x && x.doc) as Array<{ doc: ItemDatum }>;
    return valid.map(x => x.doc);
  };
}