import React from 'react';
import SignUpForm from './SignupForm';
import { Redirect } from 'react-router-dom';
import AuthSwitch from './AuthSwitch';


const noop = () => {
};

const Signup = () => (
  <AuthSwitch>
    <SignUpForm onSignIn={noop}/>
    <Redirect to="/"/>
  </AuthSwitch>
);

export default Signup;
