import React, { Component } from 'react';
import { signout } from '../storage';
import { Redirect } from 'react-router-dom';
import AuthSwitch from './AuthSwitch';


export default class Signout extends Component {
  componentDidMount() {
    signout();
  }

  render() {
    return (
      <AuthSwitch>
        <Redirect to="/"/>
        <p>Signing out...</p>
      </AuthSwitch>
    );
  }
}
