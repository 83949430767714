import React, { Component } from "react"
import PropTypes from "prop-types"
import "./OrbitalSystem.css"


const SystemContext = React.createContext({radius: 1, centerX: 0, centerY: 0, offsets: {x: 0, y: 0}})

export default class OrbitalSystem extends Component {
  static propTypes = {
    size: PropTypes.number.isRequired,
    background: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  constructor(props) {
    super(props)

    this.onResize = this.onResize.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.ref = React.createRef()
    this.state = {offsets: {x: 0, y: 0}, scrollY: 0}

    this.onScrollScheduled = false
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize)
    window.addEventListener("scroll", this.onScroll)
    this.setState({offsets: this.ref.current.getBoundingClientRect()})
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
    window.removeEventListener("scroll", this.onScroll)
  }

  onResize() {
    this.setState({offsets: this.ref.current.getBoundingClientRect()})
  }

  onScroll() {
    if (!this.onScrollScheduled) {
      window.requestAnimationFrame(() => {
        this.onScrollScheduled = false
        this.setState({scrollY: window.scrollY})
      })
      this.onScrollScheduled = true
    }
  }

  render() {
    const {children, background} = this.props
    const {offsets, scrollY} = this.state

    const elementSize = Math.min(offsets.width)
    const size = this.props.size * elementSize

    const context = {
      radius: size / 2,
      centerX: size / 2,
      centerY: size / 2,
      offsets: {x: offsets.x, y: offsets.y - scrollY},
    }

    const style = {width: size, height: size, position: "relative"}

    if (background) {
      style.backgroundImage = `url(${background})`
      style.backgroundSize = "cover"
    }

    return (<div className='orbital-system'
                 style={({width: "100%"})}
                 ref={this.ref}>
      <div style={style}>
        <SystemContext.Provider value={context}>
          {children}
        </SystemContext.Provider>
      </div>
    </div>)
  }
}

export { SystemContext }
