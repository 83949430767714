import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Arc, Circle, HalfSliceBottom, HalfSliceTop, OrbitalSystem, Planet, Slice, Tick } from '../components/circular';
import DragOverlay from '../components/circular/DragOverlay';
import DragHere from '../DragHere.png';
import { ClockView } from '../state';
import IClockStore from '../state/IClockStore';
import { angleToTextTime, cityToOffset, moduloCircle } from './tools';


const Tutorial = () => (
  <div className="tutorial">
    <img src={DragHere} alt="Drag the Clock"/>
  </div>);

type ClockProps = {
  store: IClockStore,
  size: number,
  view: ClockView
}

type ClockState = {
  windowWidth: number,
  windowHeight: number,
}

@observer
export default class Clock extends Component<ClockProps, ClockState> {
  constructor(props: ClockProps) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  onDrag = (delta: number): void => {
    const { view } = this.props;
    view.updateDelta(delta);
  };

  render() {
    const { size, view, store } = this.props;

    const now = view.now;
    const delta = view.delta;

    const items = Object.entries(store.items)
      .map(([k, v]) => ({ ...v, id: k }));

    const ZERO_AT_TOP = 90;
    const SLICE_ANGLE = 15; // one per hour = 360 / 24 = 15

    const angleToSlice = (x: number) => Math.floor(x / SLICE_ANGLE) % (360 / SLICE_ANGLE);

    const localTimeAngle = (now.getHours() * 60 + now.getMinutes() + now.getSeconds() / 60) / (24 * 60) * 360;
    const UTCTimeAngle = (now.getTimezoneOffset() / (24 * 60) * 360) + localTimeAngle;

    const itemsAngles = items.map(x => moduloCircle(delta + cityToOffset(now, x.city) + UTCTimeAngle));
    const itemsSlices = itemsAngles.map(angleToSlice);

    const activeSlices = _.uniq([...itemsSlices, angleToSlice(localTimeAngle + delta)]);
    const activeSlicesWithQuarters = _.uniq([0, 3, 6, 9, 12, 15, 18, 21, ...activeSlices]);

    // assign arcs to each user to avoid overlaps
    const itemsArcs: any = {}; // user index -> arc id
    let maxArc = 0;
    const forbiddenArcs = _.range(360 / SLICE_ANGLE).map(() => new Set());
    const sortedUsersSlices = _.sortBy(
      itemsSlices.map((slice, userIndex) => ({ slice, userIndex })),
      ['userIndex'],
    );

    sortedUsersSlices.forEach(({ slice, userIndex }) => {
      let arc = 0;

      for (; arc <= items.length; arc++) {
        if (!forbiddenArcs[slice].has(arc)) {
          break;
        }
      }

      itemsArcs[userIndex] = arc;

      const nextIndex = (slice < forbiddenArcs.length - 1) ? slice + 1 : 0;
      const previousIndex = (slice > 0) ? slice - 1 : forbiddenArcs.length - 1;

      forbiddenArcs[slice].add(arc);
      forbiddenArcs[nextIndex].add(arc);
      forbiddenArcs[previousIndex].add(arc);

      maxArc = Math.max(maxArc, arc);
    });

    // const size = Math.min(windowWidth, windowHeight) * 0.85

    const arcCount = maxArc + 1;
    const arcToDist = (arc: number): number => maxArc > 0 ? 0.2 + 0.6 * ((maxArc - arc) / maxArc) : 0.5;

    const tutorial = (view.showTuto
      ? [<Tutorial key="tuto"/>]
      : []);

    let arms = [];

    const secondsAngle = view.seconds / 59 * 360;

    if (localTimeAngle === UTCTimeAngle) {
      arms = [
        <Tick key="utcTime" className="utcTime" angle={delta + UTCTimeAngle + ZERO_AT_TOP}
              length={.97}
        />,
        <Tick key="utcTimeOther" className="utcTimeOther" angle={180 + delta + UTCTimeAngle + ZERO_AT_TOP}
              length={0.12}
        />,
        <Tick key="seconds" className="seconds" angle={secondsAngle}
              length={0.65}
              width={2}
        />,
      ];
    } else {
      arms = [
        <Tick key="localTime" className="localTime" angle={delta + localTimeAngle + ZERO_AT_TOP}
              length={0.97}
        />,
        <Tick key="localTimeOther" className="localTimeOther" angle={180 + delta + localTimeAngle + ZERO_AT_TOP}
              length={0.12}
        />,
        <Tick key="seconds" className="seconds" angle={secondsAngle}
              length={0.65}
              width={2}
        />,
      ];
    }

    return (
      <div className="Clock">
        <OrbitalSystem
          size={size}
        >
          {[
            <HalfSliceBottom key="half-bottom"/>,
            <HalfSliceTop key="half-top"/>,
            ...activeSlices.map((x) => (
              // TODO: debug this magical `+ 90`
              <Slice key={`slice-${x}`} angle={x * SLICE_ANGLE + ZERO_AT_TOP + 90 + 7.5}/>
            )),
            ...arms,
            <Circle key="clockCircle" className="clockCircle"
                    radius={0.1}
            />,
            ...activeSlicesWithQuarters.map(x => (
              <Tick key={`slice-text-${x}`} className={'activeSlice'} angle={x * SLICE_ANGLE + ZERO_AT_TOP}
                    dist={0.96} text={angleToTextTime(x * SLICE_ANGLE)}/>
            )),
            ..._.range(arcCount).map((i) => (
              <Arc
                key={`arc-${i}`}
                radius={arcToDist(i)}/>)),
            <Circle key="clockAdd" className="clockAdd"
                    radius={0.2}
            />,
            ...items.map((x, i) => (
              <Planet
                key={`planet-${i}`}
                highlight={false}
                radius={0.1} angle={itemsAngles[i] + ZERO_AT_TOP}
                image={x.img}
                text={x.name}
                dist={arcToDist(itemsArcs[i])}/>
            )),
            ...tutorial,
            <DragOverlay key="drag-overlay"
                         onDrag={this.onDrag}
            />,
          ]}
        </OrbitalSystem>
      </div>
    );
  }
}
