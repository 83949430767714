import * as _ from "lodash"


const getIn = _.get

// Careful, only works with maps.
const assocIn = (m, path, value) => {
  if (path.length === 0) {
    return value
  }

  const [first, ...rest] = path
  m = m || {}

  return {
    ...m,
    [first]: assocIn(m[first], rest, value),
  }
}

const assocInMany = (m, path, value, ...rest) => {
  const r = assocIn(m, path, value)

  if (rest.length === 0) {
    return r
  }
  return assocInMany(r, ...rest)
}

const withoutTrailing = (s) => (
  s[s.length - 1] === "/" ? s.slice(0, -1) : s
)

const toHashMap = (map) => {
  const r = {}
  map.forEach((v, k) => {
    r[k] = v
  })
  return r
}

export { getIn, assocIn, assocInMany, withoutTrailing, toHashMap }

export function asSlug(s) {
  // https://gist.github.com/mathewbyrne/1280286
  return s.toString().toLowerCase()
    .replace(/\s+/g, "-")           // Replace spaces with -
    .replace(/[^\w-]+/g, "")       // Remove all non-word chars
    .replace(/--+/g, "-")         // Replace multiple - with single -
    .replace(/^-+/, "")             // Trim - from start of text
    .replace(/-+$/, "")            // Trim - from end of text
}
