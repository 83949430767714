import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import * as firebaseui from 'firebaseui';


// Basic config
// ------------
const CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

console.log(firebase.apps);
if (firebase.apps.length === 0) {
  firebase.initializeApp(CONFIG);
  console.error('initialize');
  console.log(firebase.apps);
}

// Start firestore
// ---------------
const firestore = firebase.firestore();

// Start database
// --------------
const database = firebase.database();

// Start auth
const auth = firebase.auth();
if (process.env.NODE_ENV === 'test') {
  console.log('persistence!');
  auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
}
const signout = () => auth.signOut();

// Start authui
// ------------
let auth_ui = null;
const startAuthUI = (div, onSignIn) => {
  auth_ui = auth_ui || new firebaseui.auth.AuthUI(auth);
  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: (user, redirectUrl) => {
        if (onSignIn) {
          onSignIn(user);
          return false;
        }
        return true;
      },
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
  };

  auth_ui.start('#firebaseui-auth', uiConfig);
};

const resetAuthUI = () => {
  auth_ui.reset();
};

// Finally
// -------
export { auth, signout, startAuthUI, resetAuthUI, firestore, database };


