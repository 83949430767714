import React from "react"
import { STATUS } from "../constants"
import { Link } from "react-router-dom"
import { withoutTrailing } from "../tools"


export const ItemsList = ({items, match, showAdd, onRemove}) => {
  const addLink = !onRemove ? "#" : `${withoutTrailing(match.url)}/add`
  return (<ul className="ItemsList list-group">
    <React.Fragment>
      {Object.entries(items).map(([id, x]) => {
        const {status} = x

        if (status === STATUS.NONE || status === STATUS.LOADING) {
          return <li key={id} className="Item loading">
            Loading...
          </li>
        }
        if (status === STATUS.FAILED) {
          return <li key={id} className="Item failed">
            Failed...
          </li>
        }

        const {name, img} = x

        const editLink = !onRemove ? "#" : `${withoutTrailing(match.url)}/edit/${id}`

        return (
          <li key={id} className="Item list-group-item row d-flex">
            <div className="Pic col-2">
              <Link to={editLink}>
                <img src={img} alt={`pic for ${name}`} className="img-fluid"/>
              </Link>
            </div>
            <div className="Name col-8">
              <Link to={editLink}>
                {name}
              </Link>
            </div>
            <div className="Remove col-2">
              {!!onRemove
                ? (<button className="btn btn-outline-warning btn-xs"
                           disabled={!onRemove}
                           onClick={(e) => {
                             e.preventDefault()
                             onRemove(id)
                           }}>
                  <i className="fal fa-times"/>
                </button>)
                : null}
            </div>
          </li>)
      })}
    </React.Fragment>
    {showAdd && !!onRemove
      ? (<li className="Item text-center list-group-item row d-flex">
        <div className="col">
          <Link to={addLink}>Add a user</Link>
        </div>
      </li>)
      : null}
  </ul>)
}
