import { Formik } from 'formik';
import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { ClocksStore } from '../state';
import { asSlug } from '../tools';

@inject('clocksStore')
export default class ClocksForm extends Component<{ details?: boolean, clocksStore?: ClocksStore }, {}> {
  render() {
    const { details, clocksStore } = this.props;

    return (<div className="ClocksForm">
      <Formik
        initialValues={{ title: '' }}
        validate={values => {
          let errors: any = {};
          if (!values.title) {
            errors.title = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          clocksStore!.add(values.title)
            .then(() => setSubmitting(false));
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Create your 24H Timezown Clock!
                  </h5>
                  <form onSubmit={handleSubmit} className="text-center">
                    <div className="form-group">
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        placeholder="Give your clock a name. You can change it later."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      <small id="titleDetails"
                             className="form-text text-muted">
                        {errors.title && touched.title && errors.title}
                      </small>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        name="slug"
                        className="form-control"
                        disabled={true}
                        id="slug"
                        placeholder="https://www.timezown.io/..."
                        value={values.title && `https://www.timezown.io/${asSlug(values.title)}`}
                      />
                    </div>

                    <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                      Create
                    </button>
                  </form>

                  {details
                    ? <p className="mt-2 text-muted text-center">
                      Everything is stored on your machine, we do nothing with your data.
                      Signin to synchronize your clocks between devices.
                    </p>
                    : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>);
  }
}
