import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { match, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { ClockAndItems } from '../clock';
import FormItem from '../clock/FormItem';
import { ClocksStore, ClockStore, ClockView } from '../state';
import { Status } from '../state/Status';
import { withoutTrailing } from '../tools';


@observer
class ClockPageRaw extends Component<RouteComponentProps<{ itemID: string }> & { store: ClockStore }, {}> {
  render() {
    const store: ClockStore = this.props.store;
    const m: match<{ itemID: string }> = this.props.match;

    //const { status, items, match, id, itemRemove } = this.props;

    switch (store.status) {
      case Status.NONE:
      case Status.LOADING:
        return <div>
          Loading...
        </div>;
      case Status.READY:
        return (<Switch>
          <Route path={`${withoutTrailing(m.url)}/add`}
                 children={() => <FormItem
                   store={store.prepItem()}
                   onClose={`${m.url}`}
                 />}
          />
          <Route path={`${withoutTrailing(m.url)}/edit/:itemID`}
                 children={(props) => <FormItem
                   store={store.get(m.params.itemID)}
                   onClose={`${m.url}`}
                 />}
          />
          <Route exact path={`${m.url}`}
                 children={() => <ClockAndItems
                   view={new ClockView()}
                   store={store}
                 />}
          />
        </Switch>);
      default:
        return <div>
          Error...
        </div>;
    }
  }
}

@inject('clocksStore')
@observer
export default class ClockPage extends Component<{ id: string, clocksStore?: ClocksStore }, {}> {
  render(): any {
    const { clocksStore, id } = this.props;

    switch (clocksStore!.status) {
      case Status.NONE:
      case Status.LOADING:
        return <div>Loading...</div>;
      case Status.READY:
        const C = withRouter(ClockPageRaw);
        return <C store={clocksStore!.get(id)}/>;
    }
  }
}
