import React from "react"


const Footer = () => (
  <footer className="page-footer font-small">
    <p className="designedBy">
      Created by <a href="https://www.lsenta.io" target="_blank" rel="noopener noreferrer">Laurent</a>.
      <br/>
      <a href="https://twitter.com/laurentsenta" target="_blank" rel="noopener noreferrer"><i
        className="fab fa-twitter"/></a>
    </p>
  </footer>
)

export default Footer
