import { STATUS } from "../constants"
import {
  CLOCK_LOAD,
  CLOCK_READY,
  DELTA_UPDATE,
  EDIT_CLOSE,
  EDIT_CREATE,
  EDIT_USER,
  ITEM_ADD,
  ITEM_ADD_SUCCESS,
  ITEM_REMOVE,
  ITEM_REMOVE_SUCCESS,
  ITEM_UPDATE,
  ITEMS_UPDATE,
  REFRESH_USERS_SUCCESS,
  REMOVE_USER_SUCCESS,
  TIME_UPDATE,
  UPDATE_USER_SUCCESS,
} from "./actions"
import { assocInMany } from "../tools"


const INITIAL_STATE = {
  status: STATUS.NONE,
  users: [],
  editor: {status: STATUS.NONE, target: null},
  clocks: {},
  state: {
    delta: 0,
    now: new Date(),
  },
}

function withEditorByDefault(state) {
  if (state.editor.status !== STATUS.NONE || state.users.length > 0) {
    return state
  } else {
    return {...state, editor: {status: STATUS.READY, target: null}}
  }
}

function getUser(users, id) {
  // TODO: handle missing, etc
  return users.filter(x => x.id === id)[0]
}

export default function clockReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // ClockPage Management
    case CLOCK_LOAD:
      const {id} = action.payload
      return assocInMany(
        state,
        ["clocks", id, "status"], STATUS.LOADING,
        ["clocks", id, "items"], {},
        ["clocks", id, "meta"], {},
      )
    case DELTA_UPDATE:
      return assocInMany(
        state,
        ["state", "delta"], action.payload,
      )
    case TIME_UPDATE:
      return assocInMany(
        state,
        ["state", "now"], action.payload,
      )
    case CLOCK_READY: {
      const {id, items} = action.payload

      const meta = {}
      Object.entries(items).forEach(([k, v]) => meta[k] = {status: STATUS.READY})

      return assocInMany(
        state,
        ["clocks", id, "status"], STATUS.READY,
        ["clocks", id, "items"], items,
        ["clocks", id, "meta"], meta,
      )
    }
    // Item Management
    case ITEM_ADD:
    case ITEM_UPDATE: {
      const {clockID, id, name, city, img} = action.payload
      return assocInMany(
        state,
        ["clocks", clockID, "meta", id, "status"], STATUS.LOADING,
        ["clocks", clockID, "items", id], {name, city, img},
      )
    }
    case ITEMS_UPDATE: {
      const {clockID, items} = action.payload

      // TODO: merge with current meta instead!
      const meta = {}
      Object.entries(items).forEach(([k, v]) => {
        meta[k] = {status: STATUS.READY}
      })
      return assocInMany(
        state,
        ["clocks", clockID, "items"], items,
        ["clocks", clockID, "meta"], meta,
      )
    }
    case ITEM_ADD_SUCCESS: {
      const {clockID, id} = action.payload
      return assocInMany(
        state,
        ["clocks", clockID, "meta", id, "status"], STATUS.READY,
      )
    }
    case ITEM_REMOVE: {
      const {clockID, id} = action.payload
      return assocInMany(
        state,
        ["clocks", clockID, "meta", id, "status"], STATUS.LOADING,
      )
    }
    case ITEM_REMOVE_SUCCESS: {
      const {clockID, id} = action.payload
      return assocInMany(
        state,
        ["clocks", clockID, "meta", id, "status"], STATUS.NONE,
      )
    }
    // OLD
    // ===
    case EDIT_CLOSE:
      return {...state, editor: {...state.editor, status: STATUS.NONE, target: null}}
    case EDIT_CREATE:
      return {...state, editor: {...state.editor, status: STATUS.READY, target: null}}
    case EDIT_USER:
      const user = getUser(state.users, action.id)
      return {...state, editor: {...state.editor, status: STATUS.READY, target: user}}
    case UPDATE_USER_SUCCESS:
      return {...state, editor: {status: STATUS.NONE, target: null}}
    case REMOVE_USER_SUCCESS:
      return {...state, editor: {status: STATUS.NONE, target: null}}
    case REFRESH_USERS_SUCCESS:
      return withEditorByDefault({...state, status: STATUS.READY, users: action.users})
    default:
      return state
  }
}
