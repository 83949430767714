import { User } from 'firebase';
import { computed, observable } from 'mobx';
import { auth } from '../storage';


export default class ProfileStore {
  @observable public profile: User | null;

  constructor() {
    this.profile = null;

    auth.onAuthStateChanged(user => {
      this.profile = user;
    });
  }

  @computed get isLoggedIn() {
    return !!this.profile;
  }
}

const profileStore = new ProfileStore();

export { profileStore };
