import React, { Component } from 'react';
import { Signup } from '../profile';


const Item = ({ icon, children }: any) => (
  <div className="list-group-item list-group-item-action flex-column align-items-start">
    <div className="d-flex w-100 justify-content-between">
      <p className="mb-1">
        <i className={`fal fa-${icon}`}/>
        {children}
      </p>
    </div>
  </div>
);

export default class SigninPage extends Component<{}, {}> {
  render() {
    return (
      <div className="SigninPage">
        <h2>When you signin:</h2>

        <div className="list-group">
          <Item icon='sync'>
            Your clocks will be synchronized and accessible between devices,
          </Item>
          <Item icon='envelope'>
            You will receive email updates & notifications of important changes,
          </Item>
          <Item icon='radiation'>
            <strong>None</strong> of your data will be shared with any third-party,
          </Item>
          <Item icon='question'>
            You can send me questions & requests at any time through the chat below.
          </Item>
        </div>

        <h3 className="mt-5">If you agree, please continue:</h3>

        <div className="signupCard">
          <Signup/>
        </div>
      </div>
    );
  }
}

