import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import TimezonePicker from '../react-timezone-input';
import ClockItemStore from '../state/ClockItemStore';
import { ItemContent } from '../storage/ClockDB';
import { getIn } from '../tools';


const AVATAR_API = process.env.REACT_APP_AVATAR_API;


const CloseButton = ({ onClick }: { onClick: any }) => {
  if (typeof onClick === typeof '') {
    return (
      <Link className="CloseButton"
            to={onClick}>
        <i className="fal fa-times"/>
      </Link>
    );
  }
  return (
    <span className="CloseButton"
          onClick={onClick}>
      <i className="fal fa-times"/>
    </span>
  );
};

type FormItemProps = RouteComponentProps<{}> & {
  store: ClockItemStore,
  onClose: any,
}

type FormValues = ItemContent & { scale: number }

@observer
class FormItem extends Component<FormItemProps, {}> {
  private readonly editor: React.RefObject<any>;

  constructor(props: FormItemProps) {
    super(props);

    this.editor = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
  }

  static validate(values: FormValues) {
    const errors: { name?: string, city?: string } = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.city) {
      errors.city = 'Required';
    }
    return errors;
  }

  onSubmit({ img, name, city }: ItemContent) {
    const { history, onClose, store } = this.props;

    const canvas = this.editor.current.getImageScaledToCanvas();
    const finalImg = canvas.toDataURL('image/jpeg');

    let p = store.update({ name, city, img: finalImg });

    // TODO: onClick as function
    if (typeof onClose === typeof '') {
      p = p.then(() => history.push(onClose));
    }

    return p;
  }

  render() {
    const { store, onClose } = this.props;
    const initialValues: FormValues = {
      scale: 1,
      name: store.name,
      city: store.city,
      img: store.img,
    };

    const title = 'Title';

    return (
      <div className="FormUser">
        <div className="head">
          <h2>{title}</h2>
          <CloseButton onClick={onClose}/>
        </div>
        <Formik
          initialValues={initialValues}
          validate={FormItem.validate}
          onSubmit={this.onSubmit}>
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group form-row">
                <input type="text" name="name"
                       className={`form-control ${touched.name ? (errors.name ? 'is-invalid' : 'is-valid') : ''}`}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.name}
                       placeholder={'Name'}
                />
                {touched.name && errors.name
                  ? (<div className="invalid-feedback">
                    {errors.name}
                  </div>)
                  : null}
              </div>
              <div className="form-group form-row">
                <TimezonePicker
                  className="picker-div"
                  style={({ display: 'block' })}
                  inputProps={{
                    placeholder: 'Select Timezone...',
                    name: 'city',
                    className: `${touched.city ? (errors.city ? 'is-invalid' : 'is-valid') : ''}`,
                    onBlur: handleBlur,
                  }}
                  onChange={(x: string) => setFieldValue('city', x, false)}
                  value={values.city}
                >
                  {touched.city && errors.city
                    ? (<div className="invalid-feedback">
                      {errors.city}
                    </div>)
                    : null}
                </TimezonePicker>
              </div>
              <div className="form-group form-row">
                <div className="col-md-6">
                  <AvatarEditor
                    className={'avatarEditor'}
                    ref={this.editor}
                    width={150}
                    height={150}
                    border={10}
                    borderRadius={75}
                    color={[0, 0, 0, 0.6]}
                    rotate={0}
                    image={values.img || `${AVATAR_API}/150/${values.name}`}
                    scale={values.scale}
                    crossOrigin={'anonymous'}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="resize">Resize:</label>
                    <input name="scale" type="range" id="resize"
                           className="form-control"
                           min={0.5}
                           max={2}
                           step={0.01}
                           value={values.scale}
                           onChange={handleChange}
                           onBlur={handleBlur}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="upload">Upload image:</label>
                    <input name="newImage" id="upload"
                           className="form-control-file"
                           type="file" accept="image/*"
                           onChange={(e) => setFieldValue('img', getIn(e, ['target', 'files', 0], null), false)}/>
                  </div>
                </div>
              </div>
              <div className="form-row justify-content-around">
                <div className="text-center form-group col-md-4">
                  <input className="btn-lg btn btn-success" type="submit" value={store.isNew ? 'Create' : 'Update'}
                         disabled={isSubmitting}/>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(FormItem);
