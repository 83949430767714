import React, { Component } from "react"
import PropTypes from "prop-types"
import { SystemContext } from "./OrbitalSystem"


const TickText = ({dist, angle, text}) => (
  <div className="text" style={({
    transform: `translate(-50%, -100%) rotate(${angle}deg)`,
    transformOrigin: "50% 100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    height: dist,
  })}>
          <span style={({
            display: "block",
            transformOrigin: "50% 50%",
            // transform: `rotate(-${sAngle}deg)`,
          })}>
          {text ? text : ""}
          </span>
  </div>
)

const TickArm = ({width, length, angle}) => (
  <div className='arm' style={({
    width: `${width}px`,
    height: length,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -100%) rotate(${angle}deg)`,
    transformOrigin: "50% 100%",
  })}/>
)

export default class Tick extends Component {
  static contextType = SystemContext

  static propTypes = {
    angle: PropTypes.number.isRequired,
    className: PropTypes.string,
    dist: PropTypes.number,
    text: PropTypes.string,
    length: PropTypes.number,
    width: PropTypes.number,
  }

  render() {
    const {angle, text, length, dist, className, width} = this.props

    const shiftedAndle = angle + 90

    return (<div className={`Tick ${className ? className : ""}`}>
        {length
          ? <TickArm angle={shiftedAndle} length={this.context.radius * length} width={width || 6}/>
          : null}
        {text
          ? <TickText angle={shiftedAndle} dist={this.context.radius * (dist || 1)} text={text}/>
          : null}
      </div>
    )
  }
}
