import React, { Component } from 'react';
import ClockAndItemsDemo from '../clock/ClockAndItemsDemo';
import { ClocksForm, ClocksList } from '../clocks';
import EOL from '../components/EOL';


export default class LandingPage extends Component<{}, {}> {
  render() {
    return (
      <div className="LandingPage">
        <ClocksList/>
        <ClocksForm/>

        <h2>Here's a Demo:</h2>
        <ClockAndItemsDemo/>
        <h3>Click. Drag. <span aria-label="mindblown" role="img">🤯</span></h3>

        <ClocksForm details={true}/>
      </div>
    );
  }
}

