import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SignupForm.css';
import { resetAuthUI, startAuthUI } from '../storage';


export default class SignUpForm extends Component {
  static propTypes = {
    onSignIn: PropTypes.func,
  };

  static UI_DIV_ID = 'firebaseui-auth';

  componentDidMount() {
    startAuthUI(SignUpForm.UI_DIV_ID, this.props.onSignIn);
  }

  componentWillUnmount() {
    resetAuthUI();
  }

  render() {
    return (
      <div id={SignUpForm.UI_DIV_ID}/>
    );
  }
}
