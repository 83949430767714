import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { ProfileStore } from '../state';

type AuthSwitchProps = { profileStore?: ProfileStore, children: [any, any] }

@inject('profileStore')
@observer
export default class AuthSwitch extends Component<AuthSwitchProps, {}> {
  render() {
    const { profileStore, children } = this.props;

    if (profileStore!.isLoggedIn) {
      return children[1];
    } else {
      return children[0];
    }
  }
}
