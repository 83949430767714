import PouchDB from 'pouchdb';

export type ClockContent = {
  slug: string,
  title: string,
}

export type ClockDatum = ClockContent & {
  _id: string,
  _rev?: string,
}

export default class ClocksDB {
  public static async load(): Promise<ClocksDB> {
    return new ClocksDB();
  }

  private readonly db: PouchDB.Database<ClockDatum>;

  constructor() {
    this.db = new PouchDB('user_clocks');
  }

  changes({ change }: { change: () => void }) {
    const c = this.db.changes({ since: 'now', live: true, include_docs: false });
    c.on('change', change);
  };

  public set = async (_id: string, { slug, title }: ClockContent): Promise<any> => {
    const prev = await this.db.get(_id).catch(err => null);
    const doc: ClockDatum = { _id, slug, title };
    if (prev) {
      doc._rev = prev._rev;
    }
    return await this.db.put(doc);
  };

  public remove = async (_id: string): Promise<any> => {
    const prev = await this.db.get(_id);
    return this.db.remove({ _id, _rev: prev._rev });
  };

  public items = async (): Promise<ClockDatum[]> => {
    const xs = await this.db.allDocs({ include_docs: true });
    const valid = xs.rows.filter(x => x && x.doc) as Array<{ doc: ClockDatum }>;
    return valid.map(x => x.doc);
  };
}