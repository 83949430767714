import PropTypes from "prop-types"


export const STATUS = {
  SUCCESS: "success",
  FAILED: "failed",
  NONE: "none",
  LOADING: "loading",
  READY: "ready",
}

export const StatusType = PropTypes.oneOf(Object.values(STATUS))
