import { sortBy } from 'lodash';
import { action, computed, observable } from 'mobx';
import uuid4 from 'uuid/v4';
import { ClocksDB } from '../storage';
import { ClockDatum } from '../storage/ClocksDB';
import { asSlug } from '../tools';
import ClockStore from './ClockStore';
import { Status } from './Status';

export default class ClocksStore {
  get(id: string): ClockStore {
    const c = this.clocks_.get(id);

    if (!c) {
      throw new Error('Unknown id:' + id);
    }
    return c;
  }

  @observable status: Status;
  clocks_: Map<string, ClockStore>;


  private db?: ClocksDB;

  constructor() {
    this.status = Status.LOADING;
    this.clocks_ = new Map();
    ClocksDB.load().then(this.onLoad);
  }

  private onLoad = async (db: ClocksDB): Promise<void> => {
    this.db = db;

    this.db.items().then((xs: ClockDatum[]) => {
      xs.forEach((x: ClockDatum) => {
        this.clocks_.set(x._id, new ClockStore(this, x));
      });
      this.status = Status.READY;
    });

    this.db.changes({
      change: async () => {
        if (!this.db) {
          console.error('Missing db');
          return;
        }

        // TODO: implement
        // const xs = await this.db.items();
        // this.clocks = xs.map((x) => ({ ...x, status: Status.READY }));
      },
    });
  };

  @computed
  get clocks(): ClockStore[] {
    const xs = Object.values(this.clocks_);
    return sortBy(xs, ['title']);
  }

  @action.bound
  async add(title: string): Promise<void> {
    if (!this.db) {
      throw new Error('db not loaded');
    }

    return this.db.set(uuid4(), { title, slug: asSlug(title) });
  };

  @action.bound
  async remove(id: string) {
    // TBD
  };
}

const clocksStore = new ClocksStore();
export { clocksStore };
