import { action, computed, observable } from 'mobx';
import { moduloCircle } from '../clock/tools';

export default class ClockView {
  @observable delta: number;
  @observable now: Date;
  @observable showTuto: boolean;

  constructor() {
    this.delta = 0;
    this.now = new Date();
    this.showTuto = true;
  }

  @action.bound
  updateDelta(delta: number) {
    this.showTuto = false;
    this.delta = moduloCircle(delta);
  }

  @action.bound
  resetDelta() {
    this.showTuto = false;
    this.delta = 0;
  }

  @computed
  get seconds(): number {
    return 31;
  }

}