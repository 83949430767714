import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './main.css';
import * as serviceWorker from './serviceWorker';
import { clocksStore, profileStore } from './state';

const target = document.getElementById('root');

ReactDOM.render(
  <Provider clocksStore={clocksStore} profileStore={profileStore}>
    <Router>
      <App/>
    </Router>
  </Provider>,
  target,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
