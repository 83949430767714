import { action, computed, observable } from 'mobx';
import { ItemContent, ItemDatum } from '../storage/ClockDB';
import IClockStore from './IClockStore';
import { Status } from './Status';


export default class ClockItemStore {
  @observable name: string;
  @observable city: string;
  @observable img: string;
  @observable id: string;
  @observable _rev?: string;
  @observable status: Status;

  private readonly store: IClockStore;

  constructor(store: IClockStore, content: ItemDatum) {
    this.status = Status.READY;
    this.name = content.name;
    this.city = content.city;
    this.img = content.img;
    this.id = content._id;
    this._rev = content._rev;

    this.store = store;
  }

  @action.bound
  async update({ name, city, img }: ItemContent): Promise<void> {
    this.name = name;
    this.city = city;
    this.img = img;
    this.status = Status.LOADING;
    // TODO: store
  }

  @computed
  get isNew(): boolean {
    // TODO: implement
    return false;
  }
}