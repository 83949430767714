import React, { Component } from "react"
import PropTypes from "prop-types"
import { SystemContext } from "./OrbitalSystem"
import { toRadians } from "./utils"


/**
 * Heavily inspired by:
 * https://github.com/alexsyo/react-circular-slider-bar/blob/master/src/index.js
 */
export default class Planet extends Component {
  static contextType = SystemContext

  static propTypes = {
    onClick: PropTypes.func,
    highlight: PropTypes.bool,
    image: PropTypes.string,
    text: PropTypes.string,
    radius: PropTypes.number.isRequired,
    angle: PropTypes.number.isRequired,
    dist: PropTypes.number.isRequired,
  }

  render() {
    const {highlight, radius, dist, image, angle, onClick} = this.props
    const context = this.context

    const orbitRadius = context.radius * dist
    const size = context.radius * radius * 2
    const x = context.centerX + Math.cos(toRadians(angle)) * orbitRadius
    const y = context.centerY + Math.sin(toRadians(angle)) * orbitRadius

    const planetStyle = {
      width: "100%",
      height: "100%",
    }

    if (image) {
      planetStyle.backgroundImage = `url(${image})`
      planetStyle.backgroundSize = "cover"
      planetStyle.backgroundPosition = "center center"
    }

    return (<div className='place'
                 style={({
                   position: "absolute",
                   width: size,
                   height: size,
                   left: x,
                   top: y,
                   transform: "translate(-50%, -50%)",
                 })}
      >
        <div className={`planet ${highlight ? "highlight" : ""} ${onClick ? "clickable" : null}`} style={planetStyle}
             onClick={onClick ? onClick : null}>
        </div>
      </div>
    )
  }
}
