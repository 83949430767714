import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ClocksStore, ClockStore } from '../state';
import { Status } from '../state/Status';


const ClocksListItemRaw = ({ store }: { store: ClockStore }) => (
  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-2 ClocksListItem">
    <Link to={`/clock/${store.id}`}>
      <div className="card">
        <div className="card-body text-center">
          <h5 className="card-title">{store.title}</h5>
          <p className="card-text">{store.status}</p>
        </div>
      </div>
    </Link>
    <button className="CloseButton btn btn-outline-primary btn-xs"
            onClick={() => store.remove()}>
      <i className="fal fa-times"/>
    </button>
  </div>
);

const ClocksListItem = observer(ClocksListItemRaw);

@inject('clocksStore')
@observer
export default class ClocksList extends Component<{ clocksStore?: ClocksStore }, {}> {
  render() {
    const store: ClocksStore = this.props.clocksStore!;

    switch (store.status) {
      case Status.NONE:
      case Status.LOADING:
        return <div>Loading...</div>;
      case Status.READY:
        return <div className="ClocksList row">
          {Object.values(store.clocks).map(x =>
            <ClocksListItem key={x.id} store={x}/>,
          )}
        </div>;
      default:
        return <div>Error...</div>;
    }
  }
}

//export default inject('clocksStore')(observer(ClocksListRaw));
