import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ClockView } from '../state';
import IClockStore from '../state/IClockStore';
import Clock from './Clock';
import { ItemsList } from './ItemsList';
import TextGuide from './TextGuide';


@observer
export default class ClockAndItems extends Component<{ store: IClockStore, view: ClockView }, {}> {
  render() {
    // @ts-ignore
    const { store, match, onRemove, view } = this.props;

    return (<div className="ClockAndItems">
      <div className="row">
        <div className="col">
          <TextGuide view={view}/>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 my-md-auto">
          <Clock store={store} size={0.9} view={view}/>
        </div>
        <div className="col-12 col-md-4 mt-4 my-md-auto">
          <ItemsList items={store.items} match={match} showAdd={true} onRemove={onRemove}/>
        </div>
      </div>
    </div>);
  }
}
