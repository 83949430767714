import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ClockView } from '../state';
import { angleToTextTime, moduloCircle } from './tools';


@observer
class TextGuide extends Component<{ view: ClockView }, {}> {
  render() {
    const { view } = this.props;
    const now = view.now;
    const delta = view.delta;

    const localTimeAngle = (now.getHours() * 60 + now.getMinutes() + now.getSeconds() / 60) / (24 * 60) * 360;
    const UTCTimeAngle = (now.getTimezoneOffset() / (24 * 60) * 360) + localTimeAngle;

    if (localTimeAngle === UTCTimeAngle) {
      return (<p className="TextGuide">
        <span className="LocalTime">
          Local time (UTC) is <strong>{angleToTextTime(moduloCircle(localTimeAngle + delta), { detailed: true })}</strong>
        </span>
        <br/>
        {view.delta === 0
          ? null
          : <span onClick={() => view.resetDelta()} className="reset">Reset</span>}
      </p>);
    } else {
      return (<p className="TextGuide">
        When your <span
        className="LocalTime">local time is <strong>{angleToTextTime(moduloCircle(localTimeAngle + delta), { detailed: true })}</strong>
        </span> <span
        className="UTCTime">It is <strong>{angleToTextTime(moduloCircle(UTCTimeAngle + delta), { detailed: true })}</strong> in the UTC timezone.</span>
        <br/>
        <span onClick={() => view.resetDelta()} className="reset">
            {delta === 0 ? null : 'Reset'}
        </span>
      </p>);
    }
  }
}


export default TextGuide;
