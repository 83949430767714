import { action, observable } from 'mobx';
import uuid from 'uuid/v4';
import { ClockDB } from '../storage';
import { ItemContent, ItemDatum } from '../storage/ClockDB';
import { ClockDatum } from '../storage/ClocksDB';
import ClockItemStore from './ClockItemStore';
import ClocksStore from './ClocksStore';
import IClockStore from './IClockStore';
import { Status } from './Status';

export default class ClockStore implements IClockStore {
  readonly id: string;
  @observable slug: string;
  @observable title: string;
  @observable status: Status;
  @observable items: ClockItemStore[];

  private db?: ClockDB;

  @action.bound
  public async remove(): Promise<void> {
    // TBD
  };

  public get(itemID: string): ClockItemStore {
    for (let x of this.items) {
      if (x.id === itemID) {
        return x;
      }
    }
    throw new Error('unknown itemID=' + itemID);
  };

  public prepItem(): ClockItemStore {
    return new ClockItemStore(this, { _id: uuid(), city: '', img: '', name: '' });
  }

  @action.bound
  public async set(itemID: string, content: ItemContent) {
    if (!this.db) {
      throw new Error('db not loaded');
    }
    return this.db.set(itemID, content);
  };

  private onLoad = async (db: ClockDB): Promise<void> => {
    this.db = db;

    this.db.items().then((xs: ItemDatum[]) => {
      xs.forEach(x => {
        this.items.push(new ClockItemStore(this, x));
      });
      this.status = Status.READY;
    });

    this.db.changes({
      change: async () => {
        if (!this.db) {
          console.error('Missing db');
          return;
        }

        console.log('refresh items');
        // TODO: implement
        // const xs = await this.db.items();
        // this.items = xs.map((x) => ({ ...x, status: Status.READY }));
      },
    });
  };


  constructor(store: ClocksStore, datum: ClockDatum) {
    this.id = datum._id;
    this.status = Status.LOADING;
    this.slug = datum.slug;
    this.title = datum.title;

    this.items = [];

    ClockDB.load(this.id).then(this.onLoad);
  }

  public unload = async (): Promise<void> => {
  };
}
