import { action, observable } from 'mobx';
import { ItemContent } from '../storage/ClockDB';
import ClockItemStore from './ClockItemStore';
import DemoUsers from './DemoUsers';
import IClockStore from './IClockStore';
import { Status } from './Status';

export default class ClockStoreDemo implements IClockStore {
  readonly id: string;
  @observable slug: string;
  @observable title: string;
  @observable status: Status;
  @observable items: ClockItemStore[];

  @action.bound
  public async remove(): Promise<void> {
    // TBD
  };

  public get(itemID: string): ClockItemStore {
    for (let x of this.items) {
      if (x.id === itemID) {
        return x;
      }
    }
    throw new Error('unknown itemID=' + itemID);
  };

  public prepItem(): ClockItemStore {
    throw new Error('Not Implemented');
  }

  @action.bound
  public async set(itemID: string, content: ItemContent) {
  };

  constructor() {
    this.id = 'demo';
    this.status = Status.READY;
    this.slug = 'demo';
    this.title = 'Demo';
    this.items = DemoUsers.map(x => new ClockItemStore(this, x));
  }
}
