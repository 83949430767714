import React from 'react';
import { SystemContext } from './OrbitalSystem';


export const Arc = ({ radius }: { radius: number }) => (
  <SystemContext.Consumer>
    {context => (
      <div className="arc"
           style={({
             position: 'absolute',
             width: context.radius * radius * 2,
             height: context.radius * radius * 2,
             left: context.centerX,
             top: context.centerY,
             transform: 'translate(-50%, -50%)',
           })}
      />
    )}
  </SystemContext.Consumer>
);

export const HalfSliceBottom = () => (
  <SystemContext.Consumer>
    {context => (
      <div className="HalfSliceBottom"
           style={({
             height: context.radius,
             width: context.radius * 2,
             borderBottomLeftRadius: context.radius * 2,
             borderBottomRightRadius: context.radius * 2,
             position: 'absolute',
             left: context.centerX,
             top: context.centerY,
             transform: 'translate(-50%, 0)',
           })}
      />
    )}
  </SystemContext.Consumer>
);

export const HalfSliceTop = () => (
  <SystemContext.Consumer>
    {context => (
      <div className="HalfSliceTop"
           style={({
             height: context.radius,
             width: context.radius * 2,
             borderTopLeftRadius: context.radius * 2,
             borderTopRightRadius: context.radius * 2,
             position: 'absolute',
             left: context.centerX,
             top: context.centerY,
             transform: 'translate(-50%, -99%)', // 99% or there's a fine white line on large screens.
           })}
      />
    )}
  </SystemContext.Consumer>
);

type CircleProps = { className?: string, radius: number, onClick?: () => void }

export const Circle = ({ className, radius, onClick }: CircleProps) => (
  <SystemContext.Consumer>
    {context => (
      <div className={`Circle ${className ? className : ''} ${onClick ? 'clickable' : ''}`}
           style={({
             position: 'absolute',
             left: context.centerX,
             top: context.centerY,
             width: context.radius * radius,
             height: context.radius * radius,
             transform: 'translate(-50%, -50%)',
             borderRadius: '50%',
           })}
           onClick={onClick ? onClick : undefined}
      />)}
  </SystemContext.Consumer>
);

