import { tz } from "moment-timezone"


export function cityToOffset(now, city) {
  const offset = -tz.zone(city).utcOffset(now)
  return offset / (24 * 60) * 360
}

export const moduloCircle = (x) => (
  x < 0
    ? x % 360 + 360
    : x % 360
)

export const angleToTextTime = (x, {detailed} = {detailed: false}) => {
  const mins = x / 360 * 24 * 60
  const h = Math.floor(mins / 60)

  let r = `${h < 10 ? "0" + h : h}`

  if (detailed) {
    const m = Math.round(mins % 60)
    // const s = Math.round(mins * 60 % 60)

    r = `${r}:${m < 10 ? "0" + m : m}` //:${s < 10 ? "0" + s : s}`
  }

  return r
}
