import React, { Component } from "react";

export default class EOL extends Component<{}, { visible: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { visible: true };
  }

  render() {
    const { visible } = this.state;

    if (!visible) {
        return null;
    }

    return (
      <div
        className={`alert alert-warning alert-dismissible fade ${
          visible ? "show" : ""
        }`}
        role="alert"
      >
        <p>Hello, I'm Laurent, the author of timezown.io!</p>
        <p>
          I re-designed and re-built the project at{" "}
          <a href="https://whena.re/">whena.re</a>.<br />
          It it is faster, better designed, and it will get more updates. I'd
          love it if you joined there!
        </p>
        <p>
          timezown.io will keep working. I'll make sure you are able to keep
          using it.
          <br />
          If you need help moving to <a href="https://whena.re/">whena.re</a>,
          or if you want to share any feedback: please send me a message through{" "}
          <a href="https://twitter.com/laurentsenta">twitter</a> or{" "}
          <a href="mailto:timezown@singulargarden.com">email</a>!
        </p>
        <button
          type="button"
          className="close"
          onClick={() => this.setState({ visible: !visible })}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}
