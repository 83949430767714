import React from "react"
import { SystemContext } from "./OrbitalSystem"


// TODO: can we get right of the context and use only relative / percent sizes?
const Slice = ({angle}) => (
  <SystemContext.Consumer>
    {(value) => (
      <div className="slice"
           style={({
             position: "absolute",
             top: "0",
             left: "0",
             width: "100%",
             height: "100%",
             transform: `rotate(${angle}deg)`,
             borderRadius: "50%",
           })}
      />
    )}
  </SystemContext.Consumer>
)

export default Slice
