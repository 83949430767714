import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";
import AuthSwitch from "../profile/AuthSwitch";
import EOL from './EOL';

export class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header className="navbar-light bg-light row">
          <div className="col my-auto text-left">
            <Link to="/" className="navbar-brand">
              <img
                src={logo}
                alt={"timezown.io"}
                width="40"
                height="40"
                className="d-inline-block align-top"
              />
            </Link>
          </div>
          <div className="col my-auto">
            <p className="text-center mt-2 mb-2">
              <Link to="/">
                <b>timezown.io</b>
              </Link>
              <br />
              Own your timezones{" "}
              <span className="nowrap" role="img" aria-label="planets">
                🌍🌎🌏
              </span>
            </p>
          </div>
          <div className="col my-auto">
            <div className="text-right">
              <AuthSwitch>
                <Link className="btn btn-primary" to={"/signin"}>
                  Signin
                </Link>
                <Link className="btn btn-primary" to={"/signout"}>
                  Signout
                </Link>
              </AuthSwitch>
            </div>
          </div>
        </header>
        <EOL />
      </React.Fragment>
    );
  }
}
